@import "../../assets/style/media.less"; // 媒体查询
@import "../../assets/style/classname.less"; // 类名
// @import './variable.less';
@menu-width: 424px;

.menu-open-mixin(@menu-width) {
  flex: 0 0 @menu-width;
  width: @menu-width;
  min-width: @menu-width;
  max-width: @menu-width;
}
.menu-side-mixin(@menu-width) {
  left: -@menu-width;
  flex: 0 0 @menu-width;
  width: @menu-width;
}

@{TWBaseMenuPrefixCls} {
  &-place {
    flex: 0 0 0;
    width: 0;
    min-width: 0;
    max-width: 0;
    overflow: hidden;
    transition: background-color 0.3s ease 0s, min-width 0.3s ease 0s,
      width 0.3s ease 0s, flex-basis 0.3s ease 0s,
      max-width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    &.menu-open {
      .menu-open-mixin(@menu-width);
      .responsive("xl", {
        .menu-open-mixin(@menu-width * 0.85);
      });
      .responsive("lg", {
        .menu-open-mixin(@menu-width * 0.8);
      });
      .responsive("md", {
        .menu-open-mixin(@menu-width * 0.75);
      });
      .responsive("sm", {
        .menu-open-mixin(@menu-width * 0.65);
      });
      .responsive("xs", {
        .menu-open-mixin(@menu-width * 0.6);
      });
    }
  }
  &-side {
    position: relative;
    height: 100%;
    padding: 0 24px;
    background: rgba(0, 0, 0, 0.8);
    transition: left 0.3s ease 0s;
    overflow: hidden;
    width: 100%;

    &.menu-open {
      left: 0;
    }
  }
  &-light {
    background: rgba(0, 0, 0, 0.05) !important;
  }
  &_title {
    // width: 376px;
    height: 64px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 28px;
    line-height: 64px;
    text-align: center;
    font-family: "MicrosoftYaHeiSemibold";
    &.without-collapse {
      margin-bottom: 24px;
    }
  }
  &_title_light {
    color: rgba(0, 0, 0, 0.8);
  }
  &_content {
    overflow-x: hidden;
    overflow-y: scroll;
    height: calc(100% - 64px);
    //firefox滚动条隐藏
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0;
    }
    &::-webkit-scrollbar-thumb {
      width: 0;
    }
  }
  // hack: 解决edge浏览器内阴影问题
  &_mask_padding {
    width: 100%;
    height: 135px;
  }
  &_mask {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 135px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.03) 15%,
      rgba(0, 0, 0, 0.74) 69%,
      rgba(0, 0, 0, 1) 100%
    );
    pointer-events: none;
  }
  &_mask_light{
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.03) 15%,
      rgba(255, 255, 255, 0.74) 69%,
      rgba(255, 255, 255, 1) 100%
    );
  }
  &_collapse.ant-collapse {
    position: relative;
    margin-right: -14px;
    padding-right: 14px;
    overflow-x: hidden;
    overflow-y: scroll;
    background: none;
    border: none;
    font-size: 22px;

    // &::-webkit-scrollbar {
    //   width: 0;
    // }
    // &::-webkit-scrollbar-thumb {
    //   width: 0;
    // }

    .ant-collapse-item {
      border: none;
      .ant-collapse-header {
        padding: 12px 0 20px;
        color: rgba(255, 255, 255, 0.8);
        .ant-collapse-expand-icon {
          position: absolute;
          left: calc(100% - 12px);
          .ant-collapse-arrow {
            margin-right: 0;
          }
        }
        .ant-collapse-header-text {
          font-family: "MicrosoftYaHeiSemibold";
        }
      }
    }
    .ant-collapse-content {
      background: none;
      border: none;
      > .ant-collapse-content-box {
        padding: 0;
      }
    }

    .menu-card-wrap {
      // display: flex;
      // flex-wrap: wrap;
      // align-content: flex-start;
      min-height: 126px;
      // column-gap: 22px;
      // column-gap: 16px;
      // row-gap: 24px;
    }
  }
  &_collapse_light {
    .ant-collapse-item {
      .ant-collapse-header-text {
        color: rgba(0, 0, 0, 0.8);
      }
    }
    .ant-collapse-expand-icon{
      .ant-collapse-arrow{
        color: #000;
      }
    }
  }
  &-entry {
    position: fixed;
    bottom: 16px;
    left: 34px;
    z-index: 1000;
    width: 60px;
    height: 60px;
    background-image: url("~@/assets/images/menu/entry.png");
    background-size: cover;
    cursor: pointer;
    &:hover {
      background-image: url("~@/assets/images/menu/entry_hover.png");
    }
  }
}
