@import "media.less"; // 媒体查询
@import "classname.less"; // 类名
@import (less) "./normalize.css";
@import "./base.less";

// 字体图标
@import "./../fonts/index.less";

// driver.js组件样式，配合子应用
@import "driver.js/dist/driver.min.css";

@import "~antd/es/style/themes/default.less";
@import "~antd/dist/antd.less"; // 引入官方提供的 less 样式入口文件
@import "./theme.less"; // 用于覆盖上面定义的变量

@import "./antd.less";
@import "./compatible.less";
