@import "../../assets/style/media.less"; // 媒体查询
@import "../../assets/style/classname.less"; // 类名
@{TWBaseNoticelPrefixCls} {
  display: flex;
  flex-direction: column;
  height: 400px;
  

  &-list {
    flex: 1;
    overflow: scroll;

    &::-webkit-scrollbar {
      width: 0;
    }
    &::-webkit-scrollbar-thumb {
      width: 0;
    }

    .ant-list-item {
      padding: 12px 24px;
      cursor: pointer;
      transition: all .3s;

      &:hover {
        background: #d5dce3;
      }
    }

    h4 {
      margin: 0 0 4px 0;
    }
  }

  &-tools {
    height: 46px;
    display: flex;

    > button {
      flex: 1;
      height: 46px;
    }

    > .ant-divider {
      height: 100%;
    }
  }
}