@import "../../../../../assets/style/media.less"; // 媒体查询
@import "../../../../../assets/style/classname.less"; // 类名
@iotStatisticPrefix: ~".iot-component-statistic";

@{iotStatisticPrefix} {
  display: flex;
  flex-direction: column;
  // align-items: center;
  overflow: hidden;
  &-content {
    margin-bottom: 2px;
    color: rgba(0, 0, 0, 0.75);
    font-size: 28px;
    line-height: 40px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    font-family: Arial;
    font-weight: 600;
  }
  &-title {
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    text-align: center;
  }
}
